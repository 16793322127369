.current {
    color: green;
  }

.icon-drumstick {
    background: url('/assets/images/icons/drumstick.svg');
    height: 20px;
    width: 20px;
    display: block;
    /* Other styles here */
}